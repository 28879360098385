import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Suspense } from 'react';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDidMount } from 'rooks';
import shallow from 'zustand/shallow';

import { TalkTakeaway, TranscriptQuery } from 'api';
import { client } from 'f';
import { trackVWOCustomConversionById } from 'lib/analytics/vwo';
import {
  AuthRedirectType,
  useActionOnRedirect,
  useAuthenticated
} from 'lib/auth';
import { useDidMount as useMounted } from 'lib/hooks/useDidMount';
import { useIsMobile } from 'lib/hooks/useIsBreakpointWidth/useIsMobile';
import { VideoData } from 'lib/pages/talks/slug/[...slug].props';

import { TalkPageAds } from 'components/ads';
import ContentContainer from 'components/content-container';
import TranslationBanner from 'components/pages/talks/slug/TranslationBanner';
import Hero from 'components/pages/talks/slug/hero';
import useVideoPlayerStore from 'components/video-player/store';

import { breakpoints } from '../../../../../design-tokens/constants';

import { useRelatedVideosQuery } from 'api';
import { isPrebidReady, refreshVideoAds } from 'components/ads/prebid-script';
import { sendErrorToServices } from 'lib/logging';
import { debounce } from 'lodash-es';
import AsideSectionsSkeleton from './AsideSection/Skeleton';
import { useModalStore } from './Summary/store';
import { useSailthruTrack, useTalkPaddingOnResize } from './hooks';
import { useTalkPageContext } from './talk-page-context';
import { sanitizePathname } from './utils';
import TalkTedWorkCTA from './TalkDetails/TalkTedWorkCTA';

const TalkContent = dynamic(() => import('./TalkPage/Content'), {
  ssr: false
});
const AsideSections = dynamic(() => import('./AsideSection'), {
  ssr: true,
  loading: AsideSectionsSkeleton
});
const SignInModal = dynamic(
  () => import('./Modal').then(mod => mod.SignInModal),
  {
    ssr: false
  }
);
const TalkDetails = dynamic(() => import('./TalkDetails'));
const TalkHead = dynamic(() => import('./TalkHead'));
const Summary = dynamic(() => import('./TalkPage/Summary'), {
  ssr: false
});

type PlayThruParams = {
  time: number;
  duration: number;
};

type TalkPageProps = {
  videoData: VideoData;
  transcript: TranscriptQuery | undefined;
  commentsEnabled: boolean | undefined;
  commentsLoggedInOnly: boolean | undefined;
  shortenedUrl: string | undefined;
};

const calculatePlayThruPercent = ({ time, duration }: PlayThruParams) => {
  if (duration < 1) {
    return 0;
  }
  return parseFloat((time / duration).toFixed(2)) * 100;
};

const TalkPage = ({
  videoData,
  transcript,
  shortenedUrl,
  commentsEnabled,
  commentsLoggedInOnly
}: TalkPageProps) => {
  // Guard against null videoData
  if (!videoData) {
    return null;
  }

  const hasMounted = useMounted();
  const isLoggedIn = useAuthenticated();
  const router = useRouter();
  const context = useTalkPageContext();

  // Guard against context not being initialized
  if (!context) {
    return null; // or return a loading state if preferred
  }

  const { language } = context;

  const { sendPageview } = useSailthruTrack(videoData);
  const { openModalSummary, closeAllModal, setWatchNext } = useModalStore();

  const currentPlayThruPercent = useVideoPlayerStore(calculatePlayThruPercent);
  const {
    currentTime,
    isTranscriptVisible,
    onTranscriptToggle,
    onCloseTranscript,
    callPause,
    languages,
    ready
  } = useVideoPlayerStore(
    state => ({
      currentTime: state.roundedTime,
      isTranscriptVisible: state.isTranscriptVisible,
      onTranscriptToggle: state.onTranscriptToggle,
      onCloseTranscript: state.onCloseTranscript,
      callPause: state.callPause,
      languages: state.languages,
      ready: state.ready
    }),
    shallow
  );

  const { videoRef, detailsRef, actionButtonsVisibilityPadding } =
    useTalkPaddingOnResize();

  const sidebarInnerContent = useRef<HTMLDivElement>(null);

  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);
  const [hasSidebarScrollbar, setHasSidebarScrollbar] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [playThruBannerState, setPlayThruBannerState] = useState('neverOpened');
  const [sidebarInnerContentHeight, setSidebarInnerContentHeight] = useState(0);

  const [isBodyScrollable, setIsBodyScrollable] = useState(true);

  const displayComments = useMemo(() => {
    if (commentsLoggedInOnly) {
      return commentsEnabled && isLoggedIn && hasMounted;
    }

    return commentsEnabled && hasMounted;
  }, [commentsEnabled, commentsLoggedInOnly, hasMounted, isLoggedIn]);

  const { isMobile } = useIsMobile({ checkDevice: false });

  // Track if we should open the modal after mounting
  const shouldOpenModalAfterMount = useRef<boolean>(false);

  // Set state for sidebars inner content height and whether the sidebar should render a scrollbar
  const renderSidebarScrollbar = useCallback(() => {
    if (typeof window === 'undefined') return;

    const sidebarInnerContentEl = sidebarInnerContent.current;
    if (sidebarInnerContentEl?.getBoundingClientRect) {
      const height = sidebarInnerContentEl.getBoundingClientRect().height;
      setSidebarInnerContentHeight(height);
      setHasSidebarScrollbar(height > window.innerHeight);
    }
  }, []);

  const {
    data: video,
    loading,
    error
  } = useRelatedVideosQuery({
    variables: {
      id: Number(videoData.id),
      language,
      count: 1
    },
    ssr: false
  });

  useEffect(() => {
    if (
      !loading &&
      !error &&
      video?.videos?.nodes?.[0]?.relatedVideos?.[0]?.slug
    ) {
      const slug = video.videos.nodes[0].relatedVideos[0].slug;
      setWatchNext(`/talks/${slug}`);
    }
  }, [error, loading, setWatchNext, video?.videos?.nodes]);

  // Call Sailthru track pageview on video ID change
  useEffect(() => {
    if (!hasMounted || !videoData?.id) return;

    const sendPageviewSailthru = async () => {
      try {
        await sendPageview();
      } catch (error) {
        console.error('Failed to send Sailthru pageview:', error);
      }
    };

    sendPageviewSailthru();
  }, [hasMounted, videoData?.id]);

  useEffect(() => {
    if (currentPlayThruPercent >= 90 && playThruBannerState !== 'closed') {
      setPlayThruBannerState('open');
    }
  }, [currentPlayThruPercent]);

  useEffect(() => {
    if (window.innerWidth <= breakpoints.lg) {
      setIsBodyScrollable(!isTranscriptVisible);
    }

    if (!isInitialPageLoad) {
      const url = new URL(router.asPath, 'https://www.ted.com');

      if (isTranscriptVisible) {
        url.pathname += '/transcript';
      } else {
        url.pathname = url.pathname.replace('/transcript', '');
      }

      const sanitizedPathname = sanitizePathname(url);

      router.replace(
        {
          pathname: '/talks/[...slug]',
          query: {
            ...router.query
          }
        },
        sanitizedPathname,
        { shallow: true }
      );
    }
  }, [isTranscriptVisible]);

  useEffect(() => {
    /* Will add overflow hidden to body so there isn't a double scrollbar
    or the ability to scroll the document while transcript panel is open
    */
    if (typeof window === 'undefined') return;

    const body = document.querySelector('body');
    if (!body) return;

    if (!isBodyScrollable) {
      body.style.overflow = 'hidden';
    } else {
      body.style.removeProperty('overflow');
    }
  }, [isBodyScrollable]);

  useEffect(() => {
    renderSidebarScrollbar();
  }, [sidebarInnerContentHeight]);

  useEffect(() => {
    if (isMobile && isTranscriptVisible) {
      setIsBodyScrollable(false);
    } else {
      setIsBodyScrollable(true);
    }
  }, [isMobile, isTranscriptVisible]);

  // Full page over take to display transcript component for mobile devices
  const toggleTranscriptPanel = useCallback(() => {
    if (isTranscriptVisible) {
      onCloseTranscript();
    }
    onTranscriptToggle(!isTranscriptVisible);
  }, [isTranscriptVisible, onCloseTranscript, onTranscriptToggle]);

  useDidMount(() => {
    setIsInitialPageLoad(false);
  });

  const trackVWO = debounce(time => {
    if (time === 15) {
      trackVWOCustomConversionById(210);
    }
  }, 150);

  useEffect(() => {
    trackVWO(currentTime);
  }, [currentTime]);

  useEffect(() => {
    if (!hasMounted || !ready) return;

    try {
      if (isPrebidReady()) {
        refreshVideoAds();
      }
    } catch (error) {
      sendErrorToServices('Error refreshing video ads:', error);
    }
  }, [hasMounted, ready]);

  const hasTranscript = useMemo(() => {
    return !!transcript?.translation;
  }, [transcript?.translation]);

  useEffect(() => {
    if (
      videoData.duration - 2 === currentTime &&
      hasTranscript &&
      hasMounted &&
      videoData.takeaways?.nodes.length > 0
    ) {
      const showSummary = async () => {
        try {
          // Pause the video
          await callPause(true);

          // Then handle the transcript if needed
          if (isTranscriptVisible) {
            await toggleTranscriptPanel();
          }

          // Finally show the summary modal
          setTimeout(() => {
            openModalSummary();
          }, 100);
        } catch (error) {
          console.error('Error showing summary:', error);
        }
      };

      showSummary();
    }
  }, [
    currentTime,
    hasMounted,
    hasTranscript,
    openModalSummary,
    callPause,
    isTranscriptVisible,
    toggleTranscriptPanel,
    videoData.duration
  ]);

  // Handle modal opening from auth redirect
  const handleShouldOpenModalAction = useCallback((redirectVariables: any) => {
    // Check if the shouldOpenModal flag is set in the redirect variables
    if (redirectVariables?.shouldOpenModal === 'true') {
      // Set the flag to open modal regardless of mount state
      // The effect will pick this up
      shouldOpenModalAfterMount.current = true;
    }

    return Promise.resolve();
  }, []);

  // Use the auth redirect action handler to open the modal
  useActionOnRedirect(
    AuthRedirectType.SaveTakeaway,
    handleShouldOpenModalAction
  );

  useEffect(() => {
    // Only open the modal if component is mounted and the flag is set
    if (hasMounted && shouldOpenModalAfterMount.current) {
      // Small timeout to ensure the component is fully rendered
      setTimeout(() => {
        openModalSummary();
        // Reset the flag after opening
        shouldOpenModalAfterMount.current = false;
      }, 300);
    }
  }, [hasMounted, openModalSummary]);

  useEffect(() => {
    // Don't close modals if we're about to open one
    if (hasMounted && !shouldOpenModalAfterMount.current) {
      closeAllModal();
    }

    return () => {
      closeAllModal();
    };
  }, [closeAllModal, router.asPath, hasMounted]);

  // Add route prefetching for better navigation performance
  const prefetchRelatedVideos = useMemo(() => {
    return video?.videos?.nodes?.[0]?.relatedVideos?.map(video => ({
      route: `/talks/${video.slug}`,
      priority: true
    }));
  }, [video?.videos?.nodes]);

  useEffect(() => {
    if (prefetchRelatedVideos) {
      prefetchRelatedVideos.forEach(({ route }) => {
        router.prefetch(route);
      });
    }
  }, [prefetchRelatedVideos]);

  return (
    <>
      <TranslationBanner availableLanguages={languages} />

      <Summary summarizeData={videoData.takeaways?.nodes as TalkTakeaway[]} />

      <SignInModal
        isOpen={isSignInModalOpen}
        onDismiss={() => setIsSignInModalOpen(false)}
        showButtons={client && hasMounted}
      />

      <TalkHead video={videoData} language={language} transcript={transcript} />
      <ContentContainer
        noPadding={isMobile}
        customMaxWidth="2.5xl"
        className="bg-white"
      >
        <div
          className="flex min-h-[600px] w-full flex-col lg:flex-row"
          style={{
            flex: 1,
            paddingLeft: `${actionButtonsVisibilityPadding.left}px`,
            paddingRight: `${actionButtonsVisibilityPadding.right}px`
          }}
        >
          <div className="flex w-full flex-1 flex-col lg-tui:pt-4">
            <Hero ref={videoRef} video={videoData} />
            <div className="px-5 sm-tui:px-0">
              <TalkDetails
                actionsRef={detailsRef}
                displayCommentsButton={displayComments}
                video={{ language, ...videoData }}
                shortenedUrl={shortenedUrl}
              />
              <TalkPageAds.TalkAdBilllBoardBTF />
              <div className="my-3 lg:hidden">
                <TalkTedWorkCTA />
              </div>
              <TalkContent
                {...{ videoData, displayComments, setIsSignInModalOpen }}
              />
            </div>
          </div>

          <div
            className="order-last px-5 lg:order-none sm-tui:px-0"
            style={{ contain: 'layout style paint' }}
          >
            <div className="lg:sticky lg:top-4 lg:max-w-[425px] xl:max-w-[536px]">
              <Suspense fallback={<AsideSectionsSkeleton />}>
                <AsideSections
                  ref={sidebarInnerContent}
                  renderSidebarScrollbar={renderSidebarScrollbar}
                  toggleTranscriptPanel={toggleTranscriptPanel}
                  hasSidebarScrollbar={hasSidebarScrollbar}
                  isTranscriptVisible={isTranscriptVisible || false}
                  hasRelatedVideos={videoData.relatedVideos.length > 0}
                  hasTranslations={videoData.hasTranslations}
                  hasVideoTopics={videoData.topics.nodes.length > 0}
                  videoId={videoData.id}
                  videoTopics={videoData.topics.nodes}
                />
              </Suspense>
            </div>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

export default TalkPage;
