const AsideSectionsSkeleton = () => {
  return (
    <div className="w-[300px] animate-pulse space-y-4 p-4">
      <div className="rounded h-8 w-3/4 bg-gray-200" />
      <div className="space-y-3">
        <div className="rounded h-4 w-full bg-gray-200" />
        <div className="rounded h-4 w-5/6 bg-gray-200" />
        <div className="rounded h-4 w-4/6 bg-gray-200" />
      </div>
      <div className="h-[1px] w-full bg-gray-200" />
      <div className="space-y-3">
        <div className="rounded h-4 w-full bg-gray-200" />
        <div className="rounded h-4 w-3/4 bg-gray-200" />
      </div>
    </div>
  );
};

export default AsideSectionsSkeleton;
