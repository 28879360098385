import Text from 'components/@tedui/Text';
import { Link } from 'components/router';
import cx from 'classnames';
import { useIsMobile } from 'lib/hooks/useIsBreakpointWidth/useIsMobile';
import mixpanel from 'lib/analytics/mixpanel';

const LICENSE_REQUEST_URL = 'https://license-requests.ted.com/';
const BRAND_COLOR = '#517AB2';

const TalkTedWorkCTA = () => {
  const { isMobile } = useIsMobile({
    size: 'md',
    checkDevice: false
  });

  const ctaClasses = cx(
    'border flex hidden h-0 w-0 border-b-thin border-t-thin',
    {
      'flex-col gap-0 py-2': isMobile,
      'gap-2 py-1': !isMobile
    },
    `border-[${BRAND_COLOR}]`
  );

  return (
    <div className={ctaClasses}>
      <div className="flex items-center">
        <Text
          useNewTextStyles
          variant="body2"
          color={{ override: BRAND_COLOR }}
        >
          Want to use TED Talks in your organization?
        </Text>
      </div>

      {!isMobile && (
        <div className="flex py-1" aria-hidden="true">
          <div className="border border-r-thin" />
        </div>
      )}

      <Link
        href={LICENSE_REQUEST_URL}
        className="hover:opacity-80 transition-opacity"
        onClick={() => {
          mixpanel.track('talkpage_click', {
            title: 'ted-at-work'
          });
        }}
      >
        <Text
          useNewTextStyles
          variant="body2"
          weight="font-bold"
          color={{ override: BRAND_COLOR }}
        >
          Start here
        </Text>
      </Link>
    </div>
  );
};

export default TalkTedWorkCTA;
