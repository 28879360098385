import mixpanel from 'lib/analytics/mixpanel';
import create from 'zustand';

export const [useModalStore] = create(set => ({
  isModalSummaryOpen: false,
  isModalProgressOpen: false,
  watchNextHref: '',

  openModalSummary: () => {
    mixpanel.track('component_impression', {
      component: 'AI Knowledge Summary'
    });
    set({ isModalSummaryOpen: true });
  },
  openModalProgress: () => {
    mixpanel.track('component_impression', {
      component: 'AI Knowledge Summary Progress Bar'
    });
    set({ isModalProgressOpen: true });
  },
  closeAllModal: () => {
    set({ isModalProgressOpen: false, isModalSummaryOpen: false });
  },
  setWatchNext: (watchNextHref: string) => set({ watchNextHref })
}));
