/** Cleans up pathname for talks page */
const sanitizePathname = (url: URL): string => {
  const pathnameArray = url.pathname.split('/');
  const transcriptIndex = pathnameArray.indexOf('transcript');

  const sanitizedPathname =
    transcriptIndex > -1
      ? pathnameArray.slice(0, transcriptIndex + 1).join('/')
      : pathnameArray.join('/');

  return `${sanitizedPathname}${url.search}`;
};

export default sanitizePathname;
