import Icon from 'components/@tedui/Icon';
import ISO6391 from 'iso-639-1';
import { useEffect, useId, useState } from 'react';
import { useTalkPageContext } from '../talk-page-context';
import type TranslationBannerProps from './TranslationBanner.props';

const TranslationBanner = ({ availableLanguages }: TranslationBannerProps) => {
  const id = useId();
  const { language } = useTalkPageContext();
  const requestedLanguageName = ISO6391.getName(language);

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Don't show banner if we don't have the languages list yet
    // or if the list is empty (meaning all languages are available)
    if (!availableLanguages || availableLanguages.length === 0) {
      setShowBanner(false);
      return;
    }

    const isLanguageAvailable = availableLanguages.some(
      lang => lang.languageCode === language
    );

    // Only show banner if we have languages and the selected one isn't available
    setShowBanner(!isLanguageAvailable);
  }, [language, availableLanguages]);

  if (!showBanner) {
    return null;
  }

  return (
    <div className="flex w-full bg-white">
      <div className="w-full rounded-bl-md rounded-br-md bg-blue-300 px-5 py-6 font-light text-white md:px-10 xl:px-16">
        <div className="mx-auto flex w-full max-w-content-max items-center justify-center">
          <div aria-live="polite" role="alert" id={id}>
            <span>
              Sorry, this talk has not yet been translated into:{' '}
              <span className="font-bold">{requestedLanguageName}</span>
            </span>

            <span className="mx-3">|</span>

            <span>
              Want to help translate this talk?{' '}
              <a
                href="https://www.ted.com/participate/translate/get-started"
                className="underline"
              >
                Become a TED Translator.
              </a>
            </span>
          </div>

          <button
            type="button"
            aria-label="Dismiss notice"
            onClick={() => setShowBanner(false)}
            className="ml-auto hover:opacity-50"
          >
            <Icon iconName="x" className="text-2xl" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TranslationBanner;
